(function ( $ ) {

	$( document ).on( "gform_post_render", compactForms );

	$.layoutChange( compactForms );

	function compactForms() {
		$( "form.is-compact" ).each( compactForm );
	}

	function compactForm() {
		var self   = $( this );
		var field  = self.find( ".gfield" ).not( ".is-hidden, .is-honeypot" ).last();
		var submit = self.find( ":submit" );

		if ( field.length && submit.length ) {
			submit.addClass( "is-submit" ).css( "height", field.addClass( "is-compact" ).height() );
		}
	}

})( jQuery );