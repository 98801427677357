(function ( $, config ) {

	if (
		! config.privacyNotice ||
		window.localStorage ? window.localStorage.getItem( "privacyNoticeClosed" ) : /(^|; )(privacy_notice|cookie_notice)=0/.test( document.cookie ) ) {
		return;
	}

	$( "<div class='SiteNotice' />" ).text( config.privacyNotice )
	.append( "<button class='SiteNotice-close'>Dismiss</button>" )
	.on( "click", ".SiteNotice-close", function ( ev ) {
		ev.preventDefault();

		if ( window.localStorage ) {
			window.localStorage.setItem( "privacyNoticeClosed", true );
		} else {
			var expires = new Date();
			expires.setDate( expires.getDate() + 365 );
			document.cookie = "privacy_notice=0; expires=" + expires.toUTCString() + "; path=/";
		}

		$( ev.delegateTarget ).remove();
	})
	.appendTo( document.body );

})( jQuery, window.hartreade );