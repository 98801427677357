(function ( $ ) {

	$( ".js-toggle" ).on( "click", function ( ev ) {
		ev.preventDefault();

		var target = $( this.hash );
		var open   = ! target.hasClass( "is-open" );

		target.find( ".is-open" ).removeClass( "is-open" );
		target.add( ".js-toggle[href='" + this.hash + "']" ).toggleClass( "is-open", open );
	});

})( jQuery );