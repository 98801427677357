(function ( $ ) {

	$.layoutChange = function layoutChange( cb ) {
		cb();

		$( window ).on( "layoutchange", cb );
	};

	function triggerLayoutChange() {
		$( window ).trigger( "layoutchange" );
	}

	$( window ).on( "resize orientationchange", (function () {
		var timeout;

		return function () {
			if ( timeout ) {
				window.clearTimeout( triggerLayoutChange );
			}

			timeout = window.setTimeout( triggerLayoutChange, 150 );
		};
	})());

})( jQuery );