(function ( $ ) {

	$( ".SiteNav" ).on( "click", "li.is-parent > a", function ( ev ) {
		var link = $( this );
		var item = link.parent();

		if ( ! item.hasClass( "is-open" ) ) {
			ev.preventDefault();

			var menu    = item.children( "ul" );
			var outside = menu.css( "list-style-position" ) !== "inside";

			if ( link.attr( "data-is-header" ) !== undefined && ! menu.children( ".is-header" ).length ) {
				menu.prepend(
					link.clone()
					.removeAttr( "data-is-header data-hero" )
					.wrap( "<li class='is-header' aria-hidden='true' />" )
					.parent()
				);
			}

			if ( link.attr( "data-hero" ) && outside ) {
				$( document.body ).addClass( "is-overlay" );
			}

			item.addClass( "is-open" );

			if ( outside ) {
				item.siblings( ".is-open" ).removeClass( "is-open" );

				$( document.body ).off( "click.SiteNav" ).on( "click.SiteNav", function ( ev ) {
					if ( ! $( ".SiteNav" ).find( ev.target ).length ) {
						$( ".SiteNav .is-open" ).removeClass( "is-open" );
						$( document.body ).removeClass( "is-overlay" );
						$( this ).off( "click.SiteNav" );
					}
				});
			}
		}
	})

	.on( "MSPointerOver pointerover touchstart mouseenter mouseleave", "li.is-parent", function ( ev ) {
		var touchedWas = $.data( this, "touched" );
		var touched    = touchedWas;
		var event      = ev.originalEvent;

		if ( ev.type === "mouseenter" || ev.type === "mouseleave" ) {
			var item = $( this );
			var menu = item.children( "ul" );

			if ( ! touched && menu.css( "list-style-position" ) !== "inside" ) {
				var open = ev.type === "mouseenter";
				var link = item.children( "a" );

				if ( open && link.attr( "data-is-header" ) !== undefined && ! menu.children( ".is-header" ).length ) {
					menu.prepend(
						link.clone()
						.removeAttr( "data-is-header data-hero" )
						.wrap( "<li class='is-header' aria-hidden='true' />" )
						.parent()
					);
				}

				if ( link.attr( "data-hero" ) ) {
					$( document.body ).toggleClass( "is-overlay", open );
				}

				item.toggleClass( "is-open", open );

				if ( open ) {
					item.siblings( ".is-open" ).removeClass( "is-open" );
				}
			}

			touched = false;
		} else if ( ev.type === "touchstart" || window.TouchEvent && event instanceof TouchEvent ) {
			touched = true;
		} else if ( event.pointerType ) {
			touched = event.pointerType !== ( event.MSPOINTER_TYPE_MOUSE !== undefined ? event.MSPOINTER_TYPE_MOUSE : "mouse" );
		} else {
			touched = false;
		}

		if ( touched !== touchedWas ) {
			$.data( this, "touched", touched );
		}
	});

	$.layoutChange(function () {
		$( ".SiteNav a[data-hero]" ).each(function () {
			var item = $( this );
			var menu = item.siblings( "ul" );
			var hero = menu.children( ".is-hero" );

			if ( menu.css( "list-style-position" ) !== "inside" ) {
				if ( ! hero.length ) {
					$( "<li class='is-hero' aria-hidden='true' />").html( item.data( "hero" ) ).prependTo( menu );
				}
			} else if ( hero.length ) {
				hero.remove();
			}
		});
	});

})( jQuery );