(function ( $ ) {

	function setActive() {
		var siema = this;
		var pages = $( siema.selector ).parent().find( ".Slider-page" ).removeClass( "is-active" );
		var pageNo = ( +siema.currentSlide || 0 ) + 1;

		pages.filter( "[data-slider-action=" + pageNo + "]" ).addClass( "is-active" );

		if ( $( document.documentElement ).hasClass( "is-ie" ) ) {
			/**
			 * Y
			 *  u
			 *   c
			 *    k
			 */
			requestAnimationFrame(function () {
				requestAnimationFrame(function () {
					siema.sliderFrame.style.msTransform = "translateX(-" + ( --pageNo * siema.selectorWidth ) + "px)";
				});
			});
		}
	}

	function setup() {
		var siema = this;
		var count = siema.innerElements.length;
		var nav   = $( "<nav class='Slider-nav' />" );

		nav.append( "<button class='Slider-prev' data-slider-action='prev'>Previous</button>" );
		for ( var page = 1; page <= count; page++ ) {
			$( "<button class='Slider-page' />" ).attr( "data-slider-action", page ).appendTo( nav );
		}
		nav.append( "<button class='Slider-next' data-slider-action='next'>Next</button>" );

		nav.insertAfter( siema.selector );
		nav.parent().addClass( "is-active" );

		setActive.call( siema );
	}

	$( ".Slider" ).each(function () {
		var slider = new Siema({
			selector: $( this ).wrapInner( "<div class='Slider-wrap' />" ).children().get( 0 ),
			loop: true,
			onInit: setup,
			onChange: setActive
		});

		$.data( this, "slider", slider );
	})

	.on( "click", "[data-slider-action]", function ( ev ) {
		ev.preventDefault();

		var slider = $.data( ev.delegateTarget, "slider" );
		var action = $( this ).attr( "data-slider-action" );

		switch ( action ) {
			case "next" :
				slider.next();
				break;
			case "prev" :
				slider.prev();
				break;
			default :
				slider.goTo( +action - 1 );
				break;
		}
	});

})( jQuery );