(function ( $ ) {

	$( "img" ).each(function () {
		if ( "object-fit" in this.style ) {
			return;
		}

		var self = $( this );
		var size = self.css( "background-size" );

		if ( -1 === $.inArray( size, [ "cover", "contain" ] ) ) {
			return;
		}

		self.css( "opacity", 0 )
		.wrap( size === "cover" ? "<div class='u-sizeCover' />" : "<div class='u-sizeContain' />" )
		.parent()
		.css( "backgroundImage", "url(" + ( this.currentSrc || this.src ) + ")" );
	});

})( jQuery );