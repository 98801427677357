(function ( $ ) {

	$( document.body ).on({
		keydown: function ( ev ) {
			if ( ev.which === 9 ) {
				$( this ).addClass( "is-tab-focus" );
			}
		},

		mousedown: function () {
			$( this ).removeClass( "is-tab-focus" );
		}
	});

})( jQuery );